<template>
  <div>
    <section class="section intro">
      <div class="level">
        <div class="level-left">
          <h1 class="title has-text-centered-mobile is-size-5-mobile">
            {{ $t("Add Task") }}
          </h1>
        </div>
        <div class="level-right">
          <div class="level-item"></div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="flex">
        <label class="label">Tip sarcina : </label>
        <select class="input" v-model="taskType">
          <option
            :value="item.value"
            v-for="(item, index) in taskTypeSelectValues"
            :key="index"
            >{{ item.text }}</option
          >
        </select>
      </div>
       <div class="level-right"  v-if="params && taskType === 3">
        <label class="label">Preluare date : </label>
        <b-button  :disabled="clicked" size="is-small is-primary" @click.prevent="getAdministrativeNumbers(params.credentials) "
            >Preluare numere administrative</b-button
          >
      </div>
    </section>
    <section class="section" v-if="params && taskType === 3">
      <div class="flex">
        <label class="label">OutSR : </label>
        <input class="input" v-model="params.outSR" />
      </div>
      <div class="flex">
        <label class="label">SpatialRel : </label>
        <input class="input" v-model="params.spatialRel" />
      </div>
      <div class="flex" v-if="params && params.credentials">
        <label class="label">Username : </label>
        <input class="input" v-model="params.credentials.userName" />
      </div>
      <div class="flex" v-if="params && params.credentials">
        <label class="label">Password : </label>
        <input
          class="input"
          v-model="params.credentials.password"
          type="password"
        />
      </div>

    </section>
    <section class="section" v-if="params && taskType === 1 || taskType === 2">
      <div class="flex" v-if="params && params.credentials">
        <label class="label">Username : </label>
        <input class="input" v-model="params.credentials.userName" />
      </div>
      <div class="flex" v-if="params && params.credentials">
        <label class="label">Password : </label>
        <input
          class="input"
          v-model="params.credentials.password"
          type="password"
        />
      </div>
    </section>
    <section class="section">
      <b-field v-if="fileRowsRaw.length === 0">
        <b-upload v-model="dropFiles" @input="importData" drag-drop>
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"> </b-icon>
              </p>
              <p>Drop your files here or click to upload</p>
            </div>
          </section>
        </b-upload>
      </b-field>
      <div class="table-responsive" v-if="fileRowsRaw.length > 0">
        <table class="table">
          <thead>
            <tr>
              <th
                v-for="(columnName, j) in columnNames[0]"
                :key="j"
                class="text-left"
              >
                <div>{{ columnName }}</div>
                <div class="m-b-5">
                  <select
                    v-model="columnsMatch[j]"
                    @change="columnsMatchChanged"
                  >
                    <option :value="null">Potriviți datele cu...</option>
                    <option value="nr_cad">Nr Cadastral</option>
                    <option value="judet">Judet</option>
                    <option value="uat">UAT</option>
                  </select>
                </div>
                <div>
                  <input
                    type="search"
                    placeholder="Search..."
                    v-model="searchColumns[j]"
                    @input="search"
                  />
                </div>
              </th>
              <th class="text-center">Options</th>
            </tr>
          </thead>
          <tbody v-if="filteredFileRows.length">
            <tr v-for="(row, i) in filteredFileRows" :key="i">
              <td v-for="(column, j) in row" :key="j">
                <input type="text" v-model="filteredFileRows[i][j]" />
              </td>
              <td class="text-center">
                <b-button @click="deleteRow(row)" icon-left="delete"
                  >Delete</b-button
                >
              </td>
            </tr>
          </tbody>
          <tfoot v-if="filteredFileRows.length">
            <tr>
              <td>
                <Pagination
                  :currentPage="currentPage"
                  :totalPages="totalPages"
                  @onPageSelect="onPageSelect"
                />
              </td>
            </tr>
            <!-- <tr>
                            <td :colspan="fileRows[0].length">Total records: {{ fileRows.length }}</td>
                        </tr> -->
          </tfoot>
        </table>
        <div class="level-left">
          <b-button size="is-medium is-primary" @click.prevent="createTask()"
            >Creaza sarcina</b-button
          >
        </div>
        <div class="level-right">
          <b-button size="is-medium is-danger" @click.prevent="resetData()"
            >Reset</b-button
          >
        </div>
      </div>
    </section>
    <b-loading v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import Pagination from "@/components/Pagination";

export default {
  name: "Create Task",
  components: {
    Pagination
  },
  computed: {
    countyColumnIndex() {
      let countyIndex = null;
      for (let index in this.columnsMatch) {
        if (this.columnsMatch[index] === "judet") {
          countyIndex = index;
          break;
        }
      }
      return countyIndex;
    },
    uatColumnIndex() {
      let uatIndex = null;
      for (let index in this.columnsMatch) {
        if (this.columnsMatch[index] === "uat") {
          uatIndex = index;
          break;
        }
      }
      return uatIndex;
    },
    nrCadColumnIndex() {
      let nrCadIndex = null;
      for (let index in this.columnsMatch) {
        if (this.columnsMatch[index] === "nr_cad") {
          nrCadIndex = index;
          break;
        }
      }
      return nrCadIndex;
    },
    objects() {
      let objects = [];
      if (this.fileRowsRaw) {
        for (let x = 0; x < this.fileRowsRaw.length; x++) {
          let object = {};
          const row = this.fileRowsRaw[x];
          if (this.taskType === 3) {
            objects.push(row[0]);
          } else {
            const judet = String(row[this.countyColumnIndex]);
            if (judet) {
              object.judet = judet;
              object.uat = String(row[this.uatColumnIndex]);
              object.nr_cad = String(row[this.nrCadColumnIndex]);
              objects.push(object);
            }
          }
        }
      }
      return objects;
    }
  },
  data() {
    return {
      clicked:false,
      dropFiles: [],
      fileRowsRaw: [],
      fileRows: null,
      isLoading: false,
      columnsMatch: null,
      columnNames: null,
      searchColumns: {},
      objectToBeSent: null,
      currentPage: 1,
      pageSize: 10,
      totalPages: 1,
      filteredFileRows: [],
      fileName: "",
      taskType: 0,
      taskTypeSelectValues: [
        {
          value: 0,
          text: "-Selectati-"
        },
        {
          value: 1,
          text: "Preluare adrese"
        },
        {
          value: 2,
          text: "Preluare CF-uri"
        },
        {
          value: 3,
          text: "Conrena"
        }
      ],
      params: null
    };
  },
  mounted() {},
  watch: {
    fileRows() {
      this.totalPages = this.fileRows
        ? Math.ceil(this.fileRows.length / this.pageSize)
        : 1;
      this.onPageSelect(this.currentPage);
    },
    pageSize() {
      this.totalPages = this.fileRows
        ? Math.ceil(this.fileRows.length / this.pageSize)
        : 1;
      this.onPageSelect(this.currentPage);
    },
    taskType() {
      if (this.taskType)
        this.params = {
          outSR: 3844,
          spatialRel: "esriSpatialRelIntersects",
          credentials: {}
        };
      else this.params = null;
    }
  },
  methods: {
    getAdministrativeNumbers(credentials){
        this.$store
        .dispatch("getAdministrativeNumbers", {
          credentials: credentials,
        })
        .then((response) => {
          this.clicked=true;
          console.log(response);
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: "Sarcina creata cu succes",
            type: "is-danger",
            position: "is-bottom"
          });
          this.resetData();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 403) {
            this.$store
              .dispatch("signOut")
              .then((response) => {
                console.log(response);
                this.$router.push("/sign-in");
              })
              .catch((error) => {
                console.log(error);
                this.$router.push("/sign-in");
              });
          } else {
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$t(
                error.response ? error.response.data.message : error
              ),
              type: "is-danger",
              position: "is-bottom"
            });
          }
        });
    },
    importData() {
      console.log(this.dropFiles);
      this.fileName = this.dropFiles.name;
      this.isLoading = true;
      this.resetData();
      readXlsxFile(this.dropFiles)
        .then((rows) => {
          this.isLoading = false;
          this.columnNames = rows.filter((row, index) => index === 0);
          this.fileRowsRaw = this.fileRows = rows.filter(
            (row, index) => index > 0
          );
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.flash(
            "Uploaded file could not be parsed. Are you sure is the right format, .xlsx?",
            "bg-error"
          );
        });
    },
    resetData() {
      this.fileRows = [];
      this.fileRowsRaw = [];
      this.columnsMatch = {};
    },
    columnsMatchChanged() {
      let phoneIndex = null;
      for (let index in this.columnsMatch) {
        if (this.columnsMatch[index] === "phone") {
          phoneIndex = index;
          break;
        }
      }
      if (phoneIndex !== null) {
        for (let x = 0; x < this.fileRowsRaw.length; x++) {
          this.fileRowsRaw[x][phoneIndex] = this.formatPhoneNumber(
            this.fileRowsRaw[x][phoneIndex]
          );
        }
      }
    },
    search() {
      this.fileRows = [];
      for (let x = 0; x < this.fileRowsRaw.length; x++) {
        const row = this.fileRowsRaw[x];
        let matched = true;
        for (let y = 0; y < row.length; y++) {
          const col = row[y];
          if (
            this.searchColumns[y] &&
            this.searchColumns[y] !== "" &&
            !new RegExp(this.searchColumns[y], "gi").test(col)
          ) {
            matched = false;
          }
        }
        if (matched) {
          this.fileRows.push(row);
        }
      }
    },
    deleteRow(row) {
      this.fileRowsRaw = this.fileRowsRaw.filter((r) => r !== row);
      this.fileRows = this.fileRows.filter((r) => r !== row);
    },
    createTask() {
      this.objectToBeSent = this.objects;
      this.isLoading = true;
      if(this.taskType===0){
        this.$buefy.toast.open({
              duration: 3000,
              message: 'Selectati tipul sarcinii',
              type: "is-danger",
              position: "is-bottom"
            });

            return;
      }
      this.$store
        .dispatch("createTask", {
          fileName: this.fileName,
          taskType: this.taskType,
          geoData: this.objectToBeSent,
          params: this.params
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: "Sarcina creata cu succes",
            type: "is-danger",
            position: "is-bottom"
          });
          this.resetData();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 403) {
            this.$store
              .dispatch("signOut")
              .then((response) => {
                console.log(response);
                this.$router.push("/sign-in");
              })
              .catch((error) => {
                console.log(error);
                this.$router.push("/sign-in");
              });
          } else {
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$t(
                error.response ? error.response.data.message : error
              ),
              type: "is-danger",
              position: "is-bottom"
            });
          }
        });
    },
    onPageSelect(selectedPage) {
      this.currentPage = selectedPage;
      this.filteredFileRows = this.fileRows
        ? this.fileRows.filter((row, index) => {
            let start = (this.currentPage - 1) * this.pageSize;
            let end = this.currentPage * this.pageSize;
            if (index >= start && index < end) return true;
          })
        : [];
    }
  }
};
</script>

<style scoped>
.table-responsive {
  overflow-x: auto;
}
.section {
  padding: 5px 1.5rem;
}
.flex {
  display: flex;
}
.label{
    width: 120px;
    vertical-align: middle;
    margin-bottom: 0;
    align-items: center;
    display: flex;
}
.input,
.select {
  width: unset;
}
</style>
